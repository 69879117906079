import { SideNavigationProps } from "@cloudscape-design/components/side-navigation";
import { Duration } from "luxon";
import { ValueLabel } from "../../client/interfaces";
import { getUnixTimestampDurationBeforeNow } from "../../helpers";
import {
  DOCUMENT_PROPERTY_KEY,
  DocumentMetadataName,
} from "../../views/common/documentConstant";
import { LINKS_CONFIG } from "./links";
import { RoutePath } from "./common";

export const NAVIGATION_PATH_LABEL: {
  [Key in RoutePath]: string;
} = {
  [RoutePath.HOME]: "UDS",
  [RoutePath.ALL_PROJECTS]: "All",
  [RoutePath.PROJECT]: "Project", // Dummy value, not used
  [RoutePath.DOCUMENT_DOWNLOAD]: "Download", // Dummy value, not used
};

export const NAV_ITEMS = ({
  subjectAreaOptions,
  lastUploadDateGreaterThanOptions = LAST_UPLOAD_DATE_GREATER_THAN_OPTIONS,
}: {
  subjectAreaOptions: ValueLabel[];
  lastUploadDateGreaterThanOptions?: ValueLabel[];
}): ReadonlyArray<SideNavigationProps.Item> => [
  {
    type: "link",
    text: NAVIGATION_PATH_LABEL[RoutePath.ALL_PROJECTS],
    href: `/${RoutePath.ALL_PROJECTS}`,
  },
  {
    type: "section-group",
    title: "Filters",
    items: [
      {
        type: "section",
        text: "Subject areas",
        defaultExpanded: true,
        items: subjectAreaOptions.map((subjectArea) => ({
          type: "link",
          text: subjectArea.label ?? subjectArea.value,
          href: `/${RoutePath.ALL_PROJECTS}?${DOCUMENT_PROPERTY_KEY[DocumentMetadataName.PRIMARY_SUBJECT_AREA]}==${subjectArea.value}`,
        })),
      },
      {
        type: "section",
        text: "Time frame",
        defaultExpanded: true,
        items: lastUploadDateGreaterThanOptions.map((lastUploadDate) => ({
          type: "link",
          text: lastUploadDate.label ?? lastUploadDate.value,
          href: `/${RoutePath.ALL_PROJECTS}?${DOCUMENT_PROPERTY_KEY[DocumentMetadataName.UPLOAD_DATE]}=>=${lastUploadDate.value}`,
        })),
      },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Resources",
    items: [
      // TODO: Enable document link after document wiki is created
      // {
      //   type: "link",
      //   text: "Documentation",
      //   href: LINKS_CONFIG.DOCUMENTATION_WIKI,
      //   external: true,
      // },
      {
        type: "link",
        text: "Request access",
        href: LINKS_CONFIG.REQUEST_ACCESS,
        external: true,
      },
      {
        type: "link",
        text: "Contact us",
        href: LINKS_CONFIG.CONTACT_US,
        external: true,
      },
    ],
  },
];

export const LAST_UPLOAD_DATE_GREATER_THAN_OPTIONS = [
  {
    value: getUnixTimestampDurationBeforeNow(
      Duration.fromObject({
        days: 30,
      }),
    ).toString(),
    label: "Last 30 days",
  },
  {
    value: getUnixTimestampDurationBeforeNow(
      Duration.fromObject({
        months: 3,
      }),
    ).toString(),
    label: "Last 3 months",
  },
  {
    value: getUnixTimestampDurationBeforeNow(
      Duration.fromObject({
        months: 12,
      }),
    ).toString(),
    label: "Last 12 months",
  },
];
