import omit from "lodash.omit";
import { Document, DocumentStatus, Project } from "../../../client/interfaces";
import { arrayToDict, objectMap, toLocalDate } from "../../../helpers";
import { DOCUMENT_FORM_INPUTS } from "./documentFormConfig";
import {
  DocumentFormBoolean,
  DocumentFormSubmitAction,
  DocumentInputValue,
} from "./documentFormTypes";

interface GetInitialDocumentFormValuesProps {
  documentFormSubmitAction: DocumentFormSubmitAction;
  project?: Project;
  document?: Document;
  authorAlias?: string;
}

interface GetInitialDocumentFormValuesOutput {
  defaultDocumentInputValue: DocumentInputValue;
  defaultDocumentFormIsError: DocumentFormBoolean;
  defaultDocumentFormIsModified: DocumentFormBoolean;
}

function withNonDocumentInputFieldsRemoved(
  document?: Document,
): Partial<Document> | undefined {
  return document
    ? omit(
      document,
      "documentLink",
      "documentType",
      "lastUpdatedBy",
      "lastUpdateDate",
      "lastUploadDate",
      "documentId",
    )
    : undefined;
}

function withNonDuplicableFieldsRemoved(
  documentInputValue: DocumentInputValue,
): DocumentInputValue {
  return {
    ...documentInputValue,
    documentTitle: "",
    documentSummary: "",
    fileSizeInBytes: undefined,
    fileName: [],
    additionalNote: "",
    lastUpdateComment: "",
    documentExpirationDate: "",
  };
}

const DOCUMENT_INPUT_DEFAULT_VALUE = ({
  document,
  project,
}: {
  document?: Partial<Document>;
  project?: Partial<Project>;
}): DocumentInputValue => ({
  projectId: project?.projectId ?? document?.projectId ?? "",
  projectDescription: project?.projectDescription ?? "",
  documentTitle: "",
  marketplaces: [],
  documentSummary: "",
  documentYearQuarterMonth: [],
  author: "",
  primarySubjectArea: "",
  additionalSubjectAreas: [],
  bigRocks: [],
  status: DocumentStatus.ACTIVE,
  documentTag: "",
  documentContentTypes: [],
  documentClassification: "Confidential",
  additionalNote: "",
  fileSizeInBytes: undefined,
  ...document,
  // Override document fields with document input fields
  fileName: document?.fileName
    ? [
      new File(["0"], document.fileName, {
        lastModified: document.lastUploadDate,
      }),
    ]
    : [],
  documentExpirationDate: document?.documentExpirationDate
    ? toLocalDate(document.documentExpirationDate)
    : "",
  lastUpdateComment: "",
});

export function getInitialDocumentFormValues({
  documentFormSubmitAction,
  document,
  project,
  authorAlias,
}: GetInitialDocumentFormValuesProps): GetInitialDocumentFormValuesOutput {
  let defaultDocumentInputValue: DocumentInputValue;
  switch (documentFormSubmitAction) {
  case DocumentFormSubmitAction.EDIT:
    defaultDocumentInputValue = DOCUMENT_INPUT_DEFAULT_VALUE({
      document: withNonDocumentInputFieldsRemoved(document),
      project,
    });
    break;
  case DocumentFormSubmitAction.DUPLICATE:
    defaultDocumentInputValue = withNonDuplicableFieldsRemoved(
      DOCUMENT_INPUT_DEFAULT_VALUE({
        document: {
          ...withNonDocumentInputFieldsRemoved(document),
          status: DocumentStatus.ACTIVE,
          author: authorAlias ?? "",
        },
        project,
      }),
    );
    break;
  case DocumentFormSubmitAction.CREATE:
  default:
    defaultDocumentInputValue = DOCUMENT_INPUT_DEFAULT_VALUE({
      document: { author: authorAlias ?? "" },
      project,
    });
    break;
  }

  // Run each default input value through the validation function
  const defaultDocumentFormIsError: DocumentFormBoolean = objectMap(
    arrayToDict(
      Object.values(
        DOCUMENT_FORM_INPUTS({
          defaultValue: defaultDocumentInputValue,
          // Document expiration date is assumed to be valid in any case
          externalValidate: { documentExpirationDate: () => true },
        }),
      ).map((item) => item.props),
      "fieldId",
    ),
    (props) =>
      !(
        props.validate?.(
          defaultDocumentInputValue[props.fieldId] as string &
            string[] &
            File[],
        ).isValid ?? true
      ),
  );

  const defaultDocumentFormIsModified: DocumentFormBoolean = {};

  return {
    defaultDocumentInputValue,
    defaultDocumentFormIsError,
    defaultDocumentFormIsModified,
  };
}
