import {
  Document,
  GetDocumentMetadataOptionsOutput,
  DocumentStatus,
  Project,
  WebsiteSettings,
} from "../client/interfaces";

export const mockDocuments: Document[] = [
  {
    documentId: "1e8d7a4f-9c3b-4d8b-a7f3-8c8e7f1d6e2a",
    projectId: "8eb0681e-47a9-4400-a132-779824e233b4",
    documentTitle: "Q3’23 Prime Dual Benefits TV/OLV Ads Test via XCMI AdLabs",
    documentType: "xlsx",
    marketplaces: ["UK", "DE", "FR", "ES", "IT", "NL", "TR"],
    documentSummary:
      "Overall the Dual Benefits TV/OLV ads show potential in Composite Index metrics in all the 7 measured EU locales (UK DE FR ES IT NL and TR), driven by strong Brand Recall among Gen Pop. In terms of FABB potential, the Dual Benefits TV/OLV ads show potential to lift Prime assigned perceptions in UK DE FR ES IT and NL (6/7 measured EU locales) among Gen Pop. New News is a common opportunity areas as some Gen Pop customers also find the Dual benefits ads do not bring a lot of new news, score below norm in UK ES IT and below average in TR. Likability is another opportunity area for most of the Dual Benefits ads, score at norm in UK DE FR ES and below average in NL and TR. ",
    documentYearQuarterMonth: ["2023-Q3"],
    fileName:
      "EU TV-OLV Q3'23 Batch Test for Prime Dual Benefits - Scorecard - 10 Oct 2023.xlsx",
    author: "mssuryap",
    lastUploadDate: 1697364000000,
    documentLink: "https://w.amazon.com/bin/view/XCM-Insights/ProjectArchives/",
    primarySubjectArea: "Ad Research",
    additionalSubjectAreas: ["Prime", "AdLabs"],
    bigRocks: ["Retail/Brand"],
    status: DocumentStatus.ACTIVE,
    documentContentTypes: ["EU5", "2023Q3", "Prime dual benefits"],
    documentClassification: "Confidential",
    lastUpdateDate: 1723111200000,
    lastUpdatedBy: "mssuryap",
    lastUpdateComment: "Added new tags",
    fileSizeInBytes: 100000000,
    documentExpirationDate: 1821112000000,
  },
  {
    documentId: "c9b7a2e6-f8d4-4e9b-b7c3-8d7e6f2b5c1d",
    projectId: "8eb0681e-47a9-4400-a132-779824e233b4",
    documentTitle:
      "Q3/Q4’23 Prime Everyday Magic TV/POLV campaign XCMI Ad Tracker",
    documentType: "docs",
    marketplaces: ["JP"],
    documentSummary:
      "The Q3’23 Prime “Everyday Magic” campaign in JP with the “Rainy Day” ad (Flight 3) shows strength in Trifecta upper funnel, with strong scores in Brand Linkage in Composite index among Gen Pop. ",
    documentYearQuarterMonth: ["2023-Q3"],
    fileName:
      "JP OCT 23_AD TRACKER IN-MARKET REPORT FOR Q3'23 ('PRIME EVERYDAY MAGIC - 'RAINY DAY')_19 Oct 23.docx",
    author: "ramakm",
    lastUploadDate: 1728986400000,
    documentLink: "https://w.amazon.com/bin/view/XCM-Insights/ProjectArchives/",
    primarySubjectArea: "Ad Research",
    additionalSubjectAreas: ["Prime", "Ad Tracker"],
    bigRocks: ["Retail/Brand"],
    status: DocumentStatus.ACTIVE,
    documentContentTypes: ["Prime", "2023Q3", "2023Q4", "TV", "POLV"],
    documentClassification: "Confidential",
    lastUpdateDate: 1723111200000,
    lastUpdatedBy: "kedarasi",
    lastUpdateComment: "Initial version",
    fileSizeInBytes: 200000,
  },
  {
    documentId: "8f3e2b7d-4c1f-4e8a-9f8c-b6d8e9f2a3c5",
    projectId: "8eb0681e-47a9-4400-a132-779824e233b4",
    documentTitle: "XCMI News letter - 2024 March",
    documentType: "pdf",
    marketplaces: ["UK", "DE", "FR", "ES", "IT", "NL", "TR"],
    documentSummary:
      "This news letter features the new Gen Z study conducted in partenship with Ypluse.",
    documentYearQuarterMonth: ["2024-03"],
    fileName: "XCMI Newsletter_March'24.pdf",
    author: "dsohanla",
    lastUploadDate: 1712743200000,
    documentLink: "https://w.amazon.com/bin/view/XCM-Insights/ProjectArchives/",
    primarySubjectArea: "News letter",
    additionalSubjectAreas: [],
    bigRocks: ["Retail/Brand", "Prime"],
    status: DocumentStatus.EXPIRED,
    documentContentTypes: ["News letter"],
    documentClassification: "Confidential",
    lastUpdateDate: 1723111200000,
    lastUpdatedBy: "dsohanla",
    lastUpdateComment: "Added new tags",
    fileSizeInBytes: 2000000,
  },
  {
    documentId: "8f3e2b7d-4c1f-4e8a-9f8c-b6d8e9f2a3c6",
    projectId: "8eb0681e-47a9-4400-a132-779824e233b4",
    documentTitle:
      "Prime Day 2023 XCMI Ad Test via AdLabs (launch test) scorecard",
    documentType: "xlsx",
    marketplaces: [
      "US",
      "CA",
      "UK",
      "DE",
      "FR",
      "IT",
      "ES",
      "JP",
      "MX",
      "BR",
      "AU",
    ],
    documentSummary:
      "Overall, the PD'23 ads score at or above norm on all the Composite Index metrics and FABB perceptions across 11 measured locales. The ads have high relevance in 7/ 11 locals (US, CA, UK, FR, ES, IT, BR) and well liked in 6/11 locales (US, UK, FR, ES, IT, MX). These ads also have high potential to lift 'Offers me a valuable membership program' perception in 10/11 measured locales (US, CA, UK, DE, FR, ES, JP, BR, MX and AU). All the PD'23 ads (Soccer, Perfect Ten, Super Fans and Paparazzi) show high potential in Branding (recall Amazon/ Prime) in all the 11 measured locales (US, CA, EU5, JP, BR, MX and AU) among Gen Pop. Key themes customers like about the PD'23 ads",
    documentYearQuarterMonth: ["2023", "2023-Q3"],
    fileName:
      "Prime Day 2023 XCMI Ad Test via AdLabs (launch test) scorecard 14Jul2023.xlsx",
    author: "jenlc",
    lastUploadDate: 1689363284000,
    documentLink: "https://w.amazon.com/bin/view/XCM-Insights/ProjectArchives/",
    primarySubjectArea: "Ad Research",
    additionalSubjectAreas: ["Ad Labs"],
    bigRocks: ["Prime"],
    status: DocumentStatus.ACTIVE,
    documentContentTypes: [],
    documentClassification: "Confidential",
    lastUpdateDate: 1689363284000,
    lastUpdatedBy: "jenlc",
    lastUpdateComment: "Initial upload",
    fileSizeInBytes: 2000000,
  },
];

export const mockDocumentByProject = (projectId: string): Document[] => {
  if (projectId === mockProjects[0].projectId) {
    return [mockDocuments[0], mockDocuments[1]];
  } else if (projectId === mockProjects[1].projectId) {
    return [mockDocuments[2], mockDocuments[1]];
  }
  return [mockDocuments[3], mockDocuments[0]];
};

export const mockProjects: Project[] = [
  {
    projectId: "8eb0681e-47a9-4400-a132-779824e233b4",
    projectTitle: "Healthcare AdLabs - 2023",
    projectDescription:
      "On the XCM Health focused campaign, that strategically leaned into the Amazon brand (vs. Prime-focused like Hornbill) to promote Amazon One Medical and Amazon Pharmacy,  we tested ten TV/(P)OLV ads and gain insight on how consumers will view Amazon’s relationship to offering a health benefit. Results from this test will help us identify which ads best connote business relevant perceptions in driving intended behavioral outcomes (e.g. likelihood to purchase or brand recall).",
    lastUploadDate: 1723111500000, // latest project, should be the first project in the table
  },
  {
    projectId: "f8eab8b5-4b67-4d09-bc02-068d1c1bfbe3",
    projectTitle: "2024 XCMI News letter",
    projectDescription:
      "Monthly news letters published by XCMI highlighting insights from their studies and reasearch",
    lastUploadDate: 1723111300000,
  },
  {
    projectId: "f8635015-0aa5-44ae-a02b-835753f3c74f",
    projectTitle: "2023 Retail Brand Tracker Reports",
    projectDescription:
      "Quartely Retail brand tracker report and insights across markets",
    lastUploadDate: 1723111400000,
  },
];

export const mockProjectById = (projectId: string): Project => {
  const project = mockProjects.find(
    (project) => project.projectId === projectId,
  );
  if (!project) {
    throw new Error("Project not found");
  }
  return project;
};

export const mockDocumentMetadataOptions: GetDocumentMetadataOptionsOutput = {
  metaDataVersion: "0.0.1",
  marketplaces: {
    allowedValues: ["UK", "DE", "FR", "ES", "IT", "NL", "TR"],
  },
  primarySubjectAreas: {
    allowedValues: ["Ad Research", "NewsLetter", "SomeMore"],
  },
  additionalSubjectAreas: {
    allowedValues: ["Prime", "AdLabs", "AdTracker", "SomeMore"],
  },
  bigRocks: {
    allowedValues: ["Retail_Brand", "Prime", "SomeMore"],
  },
  classifications: {
    allowedValues: ["Confidential"],
  },
  documentContentTypes: {
    allowedValues: ["Type1", "Type2", "News letter"],
  },
  supportedDocumentTypes: {
    allowedValues: ["pdf", "doc", "docx", "xls", "xlsx"],
  },
};

export const mockSettings: WebsiteSettings = {
  COGNITO_USER_POOL_ID: "us-west-2_mock_user_pool_id",
  COGNITO_WEB_CLIENT_ID: "mock_web_client_id",
  COGNITO_DOMAIN: "mock-cognito-domain.auth.us-west-2.amazoncognito.com",
  COGNITO_SIGN_IN_CUSTOM_PROVIDER: "mockCustomProvider",
  API_ENDPOINT: "https://mock-api.us-west-2.amazonaws.com/dev",
};
