import React from "react";
import Link from "@cloudscape-design/components/link";
import { KeyValuePairsProps } from "@cloudscape-design/components/key-value-pairs";
import { Document, Project } from "../../../client/interfaces";
import { arrayToDict, sortArrayByArraySequence } from "../../../helpers";
import { ProjectMetadataName } from "../../common/projectConstant";
import { DOWNLOAD_LINK } from "../../../data/constants/common";
import { DocumentMetadataName } from "../../common/documentConstant";
import { DOCUMENT_TABLE_COLUMN_DEFINITIONS } from "../../common/documentTableColumnDefinition";
import { PROJECT_TABLE_COLUMN_DEFINITIONS } from "../../common/projectTableColumnDefinition";

const DOCUMENT_TABLE_COLUMN_DEFINITIONS_BY_ID = arrayToDict<
  ReturnType<typeof DOCUMENT_TABLE_COLUMN_DEFINITIONS>[0],
  DocumentMetadataName
>(DOCUMENT_TABLE_COLUMN_DEFINITIONS(), "id");

const PROJECT_TABLE_COLUMN_DEFINITIONS_BY_ID = arrayToDict<
  ReturnType<typeof PROJECT_TABLE_COLUMN_DEFINITIONS>[0],
  ProjectMetadataName
>(PROJECT_TABLE_COLUMN_DEFINITIONS(), "id");

export const DOCUMENT_DETAIL_PROJECT_INFORMATION: (
  project: Project,
) => KeyValuePairsProps.Item[] = (project: Project) =>
  [
    ProjectMetadataName.PROJECT_TITLE,
    ProjectMetadataName.PROJECT_DESCRIPTION,
  ].map((metadataName) => {
    if (metadataName === ProjectMetadataName.PROJECT_TITLE) {
      return {
        label: metadataName,
        value: project.projectTitle,
      };
    }
    return {
      label: metadataName,
      value: PROJECT_TABLE_COLUMN_DEFINITIONS_BY_ID[metadataName].cell(project),
    };
  });

export const DOCUMENT_DETAIL_GENERAL_INFORMATION: (
  document: Document,
  marketplaceSequence?: string[],
) => KeyValuePairsProps.Item[] = (document, marketplaceSequence = []) =>
  [
    DocumentMetadataName.PRIMARY_SUBJECT_AREA,
    DocumentMetadataName.MARKETPLACES,
    DocumentMetadataName.DOCUMENT_LINK,
    DocumentMetadataName.FILESIZE,
    DocumentMetadataName.STATUS,
    DocumentMetadataName.AUTHOR,
    DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH,
    DocumentMetadataName.UPLOAD_DATE,
    DocumentMetadataName.DOCUMENT_TYPE,
  ].map((metadataName) => {
    if (metadataName === DocumentMetadataName.DOCUMENT_LINK) {
      return {
        label: metadataName,
        value: (
          <Link
            href={DOWNLOAD_LINK(document.projectId, document.documentId)}
            target="_blank"
          >
            {document.fileName}
          </Link>
        ),
      };
    }
    if (metadataName === DocumentMetadataName.MARKETPLACES) {
      return {
        label: metadataName,
        value: sortArrayByArraySequence(
          document.marketplaces,
          marketplaceSequence,
        ).join(", "),
      };
    }
    return {
      label: metadataName,
      value:
        DOCUMENT_TABLE_COLUMN_DEFINITIONS_BY_ID[metadataName].cell(document),
    };
  });

export const DOCUMENT_DETAIL_ADDITIONAL_INFORMATION: (
  document: Document,
) => KeyValuePairsProps.Item[] = (document: Document) =>
  [
    DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS,
    DocumentMetadataName.BIG_ROCKS,
    DocumentMetadataName.DOCUMENT_CLASSIFICATION,
    DocumentMetadataName.DOCUMENT_EXPIRATION_DATE,
    DocumentMetadataName.ADDITIONAL_NOTES,
    DocumentMetadataName.DOCUMENT_TAG,
    DocumentMetadataName.DOCUMENT_CONTENT_TYPES,
    DocumentMetadataName.LAST_UPDATE_DATE,
    DocumentMetadataName.LAST_UPDATE_USER,
    DocumentMetadataName.LAST_UPDATE_COMMENT,
  ].map((metadataName) => {
    return {
      label: metadataName,
      value:
        DOCUMENT_TABLE_COLUMN_DEFINITIONS_BY_ID[metadataName].cell(document),
    };
  });
