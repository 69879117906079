import writeXlsxFile, { Schema } from "write-excel-file";
import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import { Document, Project } from "../../client/interfaces";
import { MessagesContextType } from "../../context/MessagingContext";
import { useBatchDocuments } from "../../hooks";
import { EXPORT_DOCUMENT_METADATA_ERROR } from "../../data/constants/errorMessage";
import {
  arrayToDict,
  getUnixTimestampDurationBeforeNow,
  toLocalDate,
} from "../../helpers";
import {
  ARRAY_SEPARATOR,
  BASE_URL,
  DOCUMENT_DETAIL_LINK,
  DOWNLOAD_LINK,
} from "../../data/constants/common";
import { ProjectMetadataName } from "./projectConstant";
import { DocumentMetadataName } from "./documentConstant";

interface DocumentWithProject
  extends Document,
    Pick<Project, "projectTitle" | "projectDescription"> {}

const EXPORT_TABLE_SCHEMA: Schema<DocumentWithProject> = [
  {
    column: ProjectMetadataName.PROJECT_TITLE,
    type: String,
    value: (doc) => doc.projectTitle,
  },
  {
    column: ProjectMetadataName.PROJECT_DESCRIPTION,
    type: String,
    value: (doc) => doc.projectDescription,
  },
  {
    column: DocumentMetadataName.DOCUMENT_TITLE,
    type: String,
    value: (doc) => doc.documentTitle,
  },
  {
    column: DocumentMetadataName.DOCUMENT_TYPE,
    type: String,
    value: (doc) => doc.documentType,
  },
  {
    column: DocumentMetadataName.MARKETPLACES,
    type: String,
    value: (doc) => doc.marketplaces.join(ARRAY_SEPARATOR),
  },
  {
    column: DocumentMetadataName.DOCUMENT_SUMMARY,
    type: String,
    value: (doc) => doc.documentSummary,
  },
  {
    column: DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH,
    type: String,
    value: (doc) => doc.documentYearQuarterMonth.join(ARRAY_SEPARATOR),
  },
  {
    column: DocumentMetadataName.FILENAME,
    type: String,
    value: (doc) => doc.fileName,
  },
  {
    column: DocumentMetadataName.AUTHOR,
    type: String,
    value: (doc) => doc.author,
  },
  {
    column: DocumentMetadataName.UPLOAD_DATE,
    type: Date,
    value: (doc) => new Date(doc.lastUpdateDate),
  },
  {
    column: "Document metadata link",
    type: String,
    value: (doc) =>
      `${BASE_URL}${DOCUMENT_DETAIL_LINK(doc.projectTitle, doc.documentTitle)}`,
  },
  {
    column: "Document download link",
    type: String,
    value: (doc) =>
      `${BASE_URL}${DOWNLOAD_LINK(doc.projectId, doc.documentId)}`,
  },
  {
    column: DocumentMetadataName.PRIMARY_SUBJECT_AREA,
    type: String,
    value: (doc) => doc.primarySubjectArea,
  },
  {
    column: DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS,
    type: String,
    value: (doc) => doc.additionalSubjectAreas?.join(ARRAY_SEPARATOR),
  },
  {
    column: DocumentMetadataName.BIG_ROCKS,
    type: String,
    value: (doc) => doc.bigRocks?.join(ARRAY_SEPARATOR),
  },
  {
    column: DocumentMetadataName.STATUS,
    type: String,
    value: (doc) => doc.status,
  },
  {
    column: DocumentMetadataName.DOCUMENT_CONTENT_TYPES,
    type: String,
    value: (doc) => doc.documentContentTypes?.join(ARRAY_SEPARATOR),
  },
  {
    column: DocumentMetadataName.DOCUMENT_TAG,
    type: String,
    value: (doc) => doc.documentTag,
  },
  {
    column: DocumentMetadataName.DOCUMENT_EXPIRATION_DATE,
    type: Date,
    value: (doc) =>
      doc.documentExpirationDate ? new Date(doc.documentExpirationDate) : "",
  },
  {
    column: DocumentMetadataName.DOCUMENT_CLASSIFICATION,
    type: String,
    value: (doc) => doc.documentClassification,
  },
  {
    column: DocumentMetadataName.ADDITIONAL_NOTES,
    type: String,
    value: (doc) => doc.additionalNote,
  },
  {
    column: DocumentMetadataName.LAST_UPDATE_DATE,
    type: Date,
    value: (doc) => new Date(doc.lastUpdateDate),
  },
  {
    column: DocumentMetadataName.LAST_UPDATE_USER,
    type: String,
    value: (doc) => doc.lastUpdatedBy,
  },
  {
    column: DocumentMetadataName.LAST_UPDATE_COMMENT,
    type: String,
    value: (doc) => doc.lastUpdateComment,
  },
];

function getDocumentWithProject(
  project: Project,
  document: Document,
): DocumentWithProject {
  return {
    ...document,
    projectTitle: project.projectTitle,
    projectDescription: project.projectDescription,
  };
}

const EXPORT_FILE_NAME = `UDS_metadata_export_${toLocalDate(getUnixTimestampDurationBeforeNow())}.xlsx`;

interface UseExportDocumentMetadataProps {
  addErrorMessage?: MessagesContextType["addErrorMessage"];
}

export function useExportDocumentMetadata({
  addErrorMessage,
}: UseExportDocumentMetadataProps) {
  // Get documents from multiple projects
  const [projects, setProjects] = useState<Project[]>([]);
  const {
    data: batchDocuments,
    error: batchDocumentError,
    isLoading: isBatchDocumentLoading,
  } = useBatchDocuments(projects.map((project) => project.projectId));

  const { mutate } = useSWRConfig();
  const refreshBatchDocuments = () =>
    mutate<Document[]>(
      (key) =>
        Array.isArray(key) &&
        key.length > 0 &&
        key[0] === "batchListDocumentsByProjectId",
      undefined,
      { revalidate: true },
    );

  useEffect(() => {
    if (batchDocumentError) {
      addErrorMessage?.(
        batchDocumentError.message,
        EXPORT_DOCUMENT_METADATA_ERROR,
      );
    }
  }, [batchDocumentError, addErrorMessage]);

  useEffect(() => {
    if (projects.length > 0 && batchDocuments) {
      const projectById = arrayToDict(projects, "projectId");
      const documentsWithProject = batchDocuments.map((doc) =>
        getDocumentWithProject(projectById[doc.projectId], doc),
      );
      writeXlsxFile(documentsWithProject, {
        fileName: EXPORT_FILE_NAME,
        schema: EXPORT_TABLE_SCHEMA,
        dateFormat: "yyyy-mm-dd",
        sheet: "export",
      }).then(() => {
        setProjects([]);
      });
    }
  }, [projects, batchDocuments]);

  function exportDocumentMetadata(project: Project[]): void;
  function exportDocumentMetadata(
    project: Project,
    documents: Document[],
  ): void;
  function exportDocumentMetadata(
    project: Project | Project[],
    documents?: Document[],
  ) {
    if (documents && !Array.isArray(project)) {
      const object = documents.map((doc) =>
        getDocumentWithProject(project, doc),
      );
      writeXlsxFile(object, {
        fileName: EXPORT_FILE_NAME,
        schema: EXPORT_TABLE_SCHEMA,
        dateFormat: "yyyy-mm-dd",
        sheet: "export",
      });
    } else {
      Array.isArray(project) && setProjects(project);
    }
  }

  return {
    isExporting: isBatchDocumentLoading,
    exportDocumentMetadata,
    refreshBatchDocuments,
  };
}
