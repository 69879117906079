import useSWR from "swr";
import {
  CreateDocumentCommand,
  CreateDocumentCommandInput,
  CreateDocumentUrlCommand,
  CreateProjectCommand,
  DeleteDocumentCommand,
  DeleteDocumentCommandInput,
  DeleteProjectCommand,
  DeleteProjectCommandInput,
  DocumentMetadataOptionsAllowedValue,
  DownloadDocumentCommand,
  GetDocumentMetadataOptionsCommand,
  GetDocumentTitlesCommand,
  GetProjectByIdCommand,
  GetProjectsCommand,
  GetProjectsCommandInput,
  ListDocumentsByProjectIdCommand,
  ListDocumentsByProjectIdCommandOutput,
  UDSDocument,
  UpdateDocumentCommand,
  UpdateDocumentCommandInput,
  UpdateProjectCommand,
  UpdateProjectCommandInput,
} from "@amzn/xcm-insights-uds-core-service-client";
import useSWRMutation from "swr/mutation";
import useSWRImmutable from "swr/immutable";
import {
  mockDocumentMetadataOptions,
  mockDocuments,
  mockProjects,
} from "../__mocks__/fixtures";
import { objectMap, uploadFile } from "../helpers";
import {
  DocumentMetadataOptions,
  ValueLabel,
  CreateProjectInput,
} from "../client/interfaces";
import { getClient } from "../client/api/client";

// TODO: remove mock parameters
const USE_MOCK = false;
const USE_MOCK_OVERRIDE = false;

export function useProjects(filter: GetProjectsCommandInput = {}) {
  const { data, error, isLoading, mutate } = useSWR(
    ["getProjects", filter],
    async () => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        console.log("Creating projects...");
        console.log("getProjects filter", filter);
        return mockProjects;
      }
      const response = await getClient().send(new GetProjectsCommand(filter));
      return response.projects ?? [];
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useProject(projectId?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (projectId ? ["getProjectById", projectId] : false),
    async ([, projectId]) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        return (
          mockProjects.find((project) => project.projectId === projectId) ??
          mockProjects[0]
        );
      }
      const response = await getClient().send(
        new GetProjectByIdCommand({ projectId }),
      );
      return response.project;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useProjectByTitle(projectTitle?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (projectTitle ? ["getProjectByTitle", projectTitle] : false),
    async ([, projectTitle]) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        return (
          mockProjects.find(
            (project) => project.projectTitle === projectTitle,
          ) ?? mockProjects[0]
        );
      }
      const response = await getClient().send(
        new GetProjectsCommand({ projectTitle }),
      );
      const project = response.projects?.find(
        (project) => project.projectTitle === projectTitle,
      );
      if (!project) {
        throw new Error(`Project with title ${projectTitle} not found`);
      }
      return project;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useCreateProject() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "createProject",
    async (_key, { arg }: { arg: CreateProjectInput }) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        console.log("Creating projects...");
        return mockProjects[0];
      }
      const response = await getClient().send(
        new CreateProjectCommand({
          projectId: "00000000000000000000000000", // Dummy project Id
          projectCategory: "XCMI", // Dummy project category
          ...arg,
        }),
      );
      return response;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useUpdateProject(projectId?: string) {
  const { data, error, trigger, isMutating } = useSWRMutation(
    () => (projectId ? ["updateProject", projectId] : false),
    async (_key, { arg }: { arg: UpdateProjectCommandInput }) => {
      const response = await getClient().send(new UpdateProjectCommand(arg));
      return response.project;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useDeleteProject() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "deleteProject",
    async (_key, { arg }: { arg: DeleteProjectCommandInput }) => {
      await getClient().send(new DeleteProjectCommand(arg));
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

/**
 * This hook does not revalidate automatically. Please revalidate using `mutate` manually.
 */
export function useBatchDocuments(projectIds: string[] = [], batchSize = 20) {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    () =>
      projectIds.length > 0
        ? ["batchListDocumentsByProjectId", projectIds]
        : false,
    async ([, projectIds]) => {
      const listDocumentPromises = projectIds.map((projectId) =>
        getClient().send(new ListDocumentsByProjectIdCommand({ projectId })),
      );
      let position = 0;
      const responses: ListDocumentsByProjectIdCommandOutput[] = [];
      while (position < listDocumentPromises.length) {
        const batchedResponses = await Promise.all(
          listDocumentPromises.slice(position, position + batchSize),
        );
        responses.push(...batchedResponses);
        position += batchSize;
      }
      return responses.reduce(
        (allDocuments, response) => [
          ...allDocuments,
          ...response.uDSDocumentList,
        ],
        [] as UDSDocument[],
      );
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useDocuments(projectId?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (projectId ? ["listDocumentsByProjectId", projectId] : false),
    async ([, projectId]) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        console.log("Getting documents...");
        return mockDocuments;
      }
      const response = await getClient().send(
        new ListDocumentsByProjectIdCommand({ projectId }),
      );
      return response.uDSDocumentList;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useDocumentMetadataOptions() {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    "getDocumentMetadataOptions",
    async () => {
      // TODO: remove mock implementation
      if (USE_MOCK || USE_MOCK_OVERRIDE) {
        return objectMap<
          keyof DocumentMetadataOptions,
          DocumentMetadataOptionsAllowedValue,
          ValueLabel[]
        >(mockDocumentMetadataOptions, (options) =>
          options.allowedValues?.map((item) => ({ value: item, label: item })),
        );
      }
      const response = await getClient().send(
        new GetDocumentMetadataOptionsCommand(),
      );
      return objectMap<
        keyof DocumentMetadataOptions,
        DocumentMetadataOptionsAllowedValue,
        ValueLabel[]
      >(response, (options) =>
        options.allowedValues?.map((item) => ({ value: item, label: item })),
      );
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useUploadDocumentFile() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "uploadDocumentFile",
    async (
      _,
      {
        arg: { file, documentLink },
      }: { arg: { file: File; documentLink?: string } },
    ) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        console.log("File uploaded!", file);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve("wait");
          }, 2000);
        });
        return `https://uploaded.document.path/${file.name}`;
      }
      const response = await getClient().send(
        new CreateDocumentUrlCommand({ documentLink }),
      );
      const headers = {
        "Content-Disposition": `attachment; filename=${encodeURIComponent(file.name)}; filename*=utf-8''${encodeURIComponent(file.name)}`,
      };
      await uploadFile(response.documentLink, file, headers);
      return response.documentLink;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useCreateDocument() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "createDocument",
    async (_key, { arg }: { arg: CreateDocumentCommandInput }) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        console.log("Document created!", arg);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve("wait");
          }, 2000);
        });
        return mockDocuments[0];
      }
      const response = await getClient().send(new CreateDocumentCommand(arg));
      return response.uDSDocument;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useUpdateDocument(documentId?: string) {
  const { data, error, trigger, isMutating } = useSWRMutation(
    () => (documentId ? ["updateDocument", documentId] : false),
    async (_key, { arg }: { arg: UpdateDocumentCommandInput }) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        console.log("Document updated!", arg);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve("wait");
          }, 2000);
        });
        return mockDocuments[0];
      }
      const response = await getClient().send(new UpdateDocumentCommand(arg));
      return response.uDSDocument;
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useDeleteDocument() {
  const { data, error, trigger, isMutating } = useSWRMutation(
    "deleteDocument",
    async (_key, { arg }: { arg: DeleteDocumentCommandInput }) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        console.log("Document deleted!", arg);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve("wait");
          }, 2000);
        });
        return;
      }
      await getClient().send(new DeleteDocumentCommand(arg));
    },
  );
  return {
    data,
    trigger,
    isMutating,
    error,
  };
}

export function useDownloadDocumentLink(
  projectId?: string,
  documentId?: string,
) {
  const { data, error, isLoading, mutate } = useSWR(
    () =>
      projectId && documentId
        ? ["downloadDocument", projectId, documentId]
        : false,
    async ([, projectId, documentId]) => {
      // TODO: remove mock implementation
      if (USE_MOCK) {
        console.log("Document downloaded...");
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve("wait");
          }, 2000);
        });
        return "https://w.amazon.com/bin/skin/skins/amazon/logo.png";
      }
      const response = await getClient().send(
        new DownloadDocumentCommand({ projectId, documentId }),
      );
      return response.documentLink;
    },
    { revalidateOnFocus: false },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}

export function useGetDocumentTitles(documentTitle?: string) {
  const { data, error, isLoading, mutate } = useSWR(
    () => (documentTitle ? ["getDocumentTitles", documentTitle] : false),
    async ([, documentTitle]) => {
      // TODO: remove mock implementation
      if (USE_MOCK || USE_MOCK_OVERRIDE) {
        console.log("checking documentTitle", documentTitle);
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 500);
        });
        return mockDocuments
          .map((doc) => doc.documentTitle)
          .filter((title) =>
            title.toLowerCase().includes(documentTitle.toLowerCase()),
          );
      }
      const response = await getClient().send(
        new GetDocumentTitlesCommand({ documentTitle }),
      );
      return response.documentTitles;
    },
  );
  return {
    data,
    isLoading,
    error,
    mutate,
  };
}
