import { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { Document } from "../../client/interfaces";

export enum DocumentMetadataName {
  DOCUMENT_TITLE = "Document title",
  DOCUMENT_TYPE = "Document type",
  MARKETPLACES = "Marketplaces",
  DOCUMENT_SUMMARY = "Document summary",
  DOCUMENT_YEAR_QUARTER_MONTH = "Document year/quarter/month",
  FILENAME = "File name",
  AUTHOR = "Author",
  UPLOAD_DATE = "Upload date",
  DOCUMENT_LINK = "Document link",
  PRIMARY_SUBJECT_AREA = "Primary subject area",
  ADDITIONAL_SUBJECT_AREAS = "Additional subject areas",
  BIG_ROCKS = "Big rocks",
  STATUS = "Status",
  DOCUMENT_TAG = "Document tag",
  DOCUMENT_CONTENT_TYPES = "Document content types",
  DOCUMENT_EXPIRATION_DATE = "Document expiration date",
  DOCUMENT_CLASSIFICATION = "Document classification",
  ADDITIONAL_NOTES = "Additional notes",
  LAST_UPDATE_DATE = "Last update date",
  LAST_UPDATE_USER = "Last update user",
  LAST_UPDATE_COMMENT = "Last update comment",
  FILESIZE = "File size",
}

export const DOCUMENT_PROPERTY_KEY: {
  [Key in DocumentMetadataName]: keyof Document | "subjectArea";
} = {
  [DocumentMetadataName.DOCUMENT_TITLE]: "documentTitle",
  [DocumentMetadataName.DOCUMENT_TYPE]: "documentType",
  [DocumentMetadataName.MARKETPLACES]: "marketplaces",
  [DocumentMetadataName.DOCUMENT_SUMMARY]: "documentSummary",
  [DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH]:
    "documentYearQuarterMonth",
  [DocumentMetadataName.FILENAME]: "fileName",
  [DocumentMetadataName.AUTHOR]: "author",
  [DocumentMetadataName.UPLOAD_DATE]: "lastUploadDate",
  [DocumentMetadataName.DOCUMENT_LINK]: "documentLink",
  [DocumentMetadataName.PRIMARY_SUBJECT_AREA]: "primarySubjectArea",
  [DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS]: "additionalSubjectAreas",
  [DocumentMetadataName.BIG_ROCKS]: "bigRocks",
  [DocumentMetadataName.STATUS]: "status",
  [DocumentMetadataName.DOCUMENT_TAG]: "documentTag",
  [DocumentMetadataName.DOCUMENT_CONTENT_TYPES]: "documentContentTypes",
  [DocumentMetadataName.DOCUMENT_EXPIRATION_DATE]: "documentExpirationDate",
  [DocumentMetadataName.DOCUMENT_CLASSIFICATION]: "documentClassification",
  [DocumentMetadataName.ADDITIONAL_NOTES]: "additionalNote",
  [DocumentMetadataName.LAST_UPDATE_DATE]: "lastUpdateDate",
  [DocumentMetadataName.LAST_UPDATE_USER]: "lastUpdatedBy",
  [DocumentMetadataName.LAST_UPDATE_COMMENT]: "lastUpdateComment",
  [DocumentMetadataName.FILESIZE]: "fileSizeInBytes",
};

export const DOCUMENT_UPLOAD_SUCCESS_MESSAGE: FlashbarProps.MessageDefinition =
  {
    type: "success",
    header: "Success!",
    content: "Document successfully uploaded.",
  };

export const DOCUMENT_EDIT_SUCCESS_MESSAGE: FlashbarProps.MessageDefinition = {
  type: "success",
  header: "Success!",
  content: "Document successfully updated.",
};

export const DOCUMENT_NO_CHANGES_INFO_MESSAGE: FlashbarProps.MessageDefinition =
  {
    type: "info",
    header: "Info!",
    content: "No changes were made to the document.",
  };

export const DOCUMENT_DELETE_SUCCESS_MESSAGE: FlashbarProps.MessageDefinition =
  {
    type: "success",
    header: "Success!",
    content: "Document successfully deleted.",
  };

export const CREATE_NEW_DOCUMENT = "Create new document";
export const EDIT_DOCUMENT = "Update document";
export const DELETE_DOCUMENT = "Delete document";
export const DELETE_DOCUMENT_CONTENT =
  "Proceeding with this action will delete the document with all its metadata.";
export const REPLACE_DOCUMENT = "File replacement";
export const REPLACE_DOCUMENT_CONTENT = (filename?: string) =>
  `Proceeding with this action will replace the current file ${filename}. Do you want to proceed?`;
