import React from "react";
import { TableProps } from "@cloudscape-design/components/table";
import Link from "@cloudscape-design/components/link";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import { Document, DocumentStatus } from "../../client/interfaces";
import {
  ARRAY_SEPARATOR,
  DOWNLOAD_LINK,
  EMPTY_PLACEHOLDER,
} from "../../data/constants/common";
import {
  sortArrayByArraySequence,
  toFileSize,
  toLocalDate,
  toPhoneToolLink,
} from "../../helpers";
import GreyLink from "../../components/common/GreyLink";
import {
  DOCUMENT_PROPERTY_KEY,
  DocumentMetadataName,
} from "./documentConstant";

interface DocumentTableColumnDefinitions {
  onTitleClick?: (doc: Document) => void;
  selectedDocument?: Document;
  widths?: ReadonlyArray<number>;
  marketplaceSequence?: string[];
}
export const DOCUMENT_TABLE_COLUMN_DEFINITIONS = ({
  onTitleClick,
  selectedDocument,
  widths = [],
  marketplaceSequence = [],
}: DocumentTableColumnDefinitions = {}): ReadonlyArray<
  TableProps.ColumnDefinition<Document> & { id: DocumentMetadataName }
> => [
  // This array determines how the document metadata is displayed in both the table view and summary view
  {
    id: DocumentMetadataName.DOCUMENT_TITLE,
    header: DocumentMetadataName.DOCUMENT_TITLE,
    cell: (e: Document) => (
      <Link
        href="#"
        onFollow={(event) => {
          event.preventDefault();
          onTitleClick?.(e);
        }}
      >
        {selectedDocument?.documentId === e.documentId ? (
          <b>{e.documentTitle}</b>
        ) : (
          e.documentTitle
        )}
      </Link>
    ),
    isRowHeader: true,
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_TITLE],
    width: widths[0],
  },
  {
    id: DocumentMetadataName.DOCUMENT_TYPE,
    header: DocumentMetadataName.DOCUMENT_TYPE,
    cell: (e: Document) => e.documentType,
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_TYPE],
    width: widths[1],
  },
  {
    id: DocumentMetadataName.MARKETPLACES,
    header: DocumentMetadataName.MARKETPLACES,
    cell: (e: Document) =>
      sortArrayByArraySequence(e.marketplaces, marketplaceSequence).join(
        ARRAY_SEPARATOR,
      ),
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.MARKETPLACES],
    width: widths[2],
  },
  {
    id: DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH,
    header: DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH,
    cell: (e: Document) => e.documentYearQuarterMonth.join(ARRAY_SEPARATOR),
    sortingField:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_YEAR_QUARTER_MONTH],
    width: widths[3],
  },
  {
    id: DocumentMetadataName.FILENAME,
    header: DocumentMetadataName.FILENAME,
    cell: (e: Document) => e.fileName,
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.FILENAME],
    width: widths[4],
  },
  {
    id: DocumentMetadataName.AUTHOR,
    header: DocumentMetadataName.AUTHOR,
    cell: (e: Document) => (
      <GreyLink href={toPhoneToolLink(e.author)} target="_blank">
        {e.author}
      </GreyLink>
    ),
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.AUTHOR],
    width: widths[5],
  },
  {
    id: DocumentMetadataName.UPLOAD_DATE,
    header: DocumentMetadataName.UPLOAD_DATE,
    cell: (e: Document) => toLocalDate(e.lastUploadDate),
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.UPLOAD_DATE],
    width: widths[6],
  },
  {
    id: DocumentMetadataName.DOCUMENT_LINK,
    header: DocumentMetadataName.DOCUMENT_LINK,
    cell: (e: Document) => (
      <Link href={DOWNLOAD_LINK(e.projectId, e.documentId)} target="_blank">
        Access here
      </Link>
    ),
    width: widths[7],
  },
  {
    id: DocumentMetadataName.PRIMARY_SUBJECT_AREA,
    header: DocumentMetadataName.PRIMARY_SUBJECT_AREA,
    cell: (e: Document) => e.primarySubjectArea,
    sortingField:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.PRIMARY_SUBJECT_AREA],
    width: widths[8],
  },
  {
    id: DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS,
    header: DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS,
    cell: (e: Document) =>
      e.additionalSubjectAreas?.join(ARRAY_SEPARATOR) || EMPTY_PLACEHOLDER,
    sortingField:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.ADDITIONAL_SUBJECT_AREAS],
    width: widths[9],
  },
  {
    id: DocumentMetadataName.BIG_ROCKS,
    header: DocumentMetadataName.BIG_ROCKS,
    cell: (e: Document) =>
      e.bigRocks?.join(ARRAY_SEPARATOR) || EMPTY_PLACEHOLDER,
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.BIG_ROCKS],
    width: widths[10],
  },
  {
    id: DocumentMetadataName.STATUS,
    header: DocumentMetadataName.STATUS,
    cell: (e: Document) => (
      <StatusIndicator
        type={e.status === DocumentStatus.ACTIVE ? "success" : "stopped"}
      >
        {e.status}
      </StatusIndicator>
    ),
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.STATUS],
    width: widths[11],
  },
  {
    id: DocumentMetadataName.DOCUMENT_CONTENT_TYPES,
    header: DocumentMetadataName.DOCUMENT_CONTENT_TYPES,
    cell: (e: Document) =>
      e.documentContentTypes?.join(ARRAY_SEPARATOR) || EMPTY_PLACEHOLDER,
    sortingField:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_CONTENT_TYPES],
    width: widths[12],
  },
  {
    id: DocumentMetadataName.DOCUMENT_EXPIRATION_DATE,
    header: DocumentMetadataName.DOCUMENT_EXPIRATION_DATE,
    cell: (e: Document) =>
      toLocalDate(e.documentExpirationDate) ?? EMPTY_PLACEHOLDER,
    sortingField:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_EXPIRATION_DATE],
    width: widths[13],
  },
  {
    id: DocumentMetadataName.DOCUMENT_CLASSIFICATION,
    header: DocumentMetadataName.DOCUMENT_CLASSIFICATION,
    cell: (e: Document) => e.documentClassification,
    sortingField:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_CLASSIFICATION],
    width: widths[14],
  },
  {
    id: DocumentMetadataName.ADDITIONAL_NOTES,
    header: DocumentMetadataName.ADDITIONAL_NOTES,
    cell: (e: Document) => e.additionalNote ?? EMPTY_PLACEHOLDER,
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.ADDITIONAL_NOTES],
    width: widths[15],
  },
  {
    id: DocumentMetadataName.LAST_UPDATE_DATE,
    header: DocumentMetadataName.LAST_UPDATE_DATE,
    cell: (e: Document) => toLocalDate(e.lastUpdateDate),
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.LAST_UPDATE_DATE],
    width: widths[16],
  },
  {
    id: DocumentMetadataName.LAST_UPDATE_USER,
    header: DocumentMetadataName.LAST_UPDATE_USER,
    cell: (e: Document) => (
      <GreyLink href={toPhoneToolLink(e.lastUpdatedBy)} target="_blank">
        {e.lastUpdatedBy}
      </GreyLink>
    ),
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.LAST_UPDATE_USER],
    width: widths[17],
  },
  {
    id: DocumentMetadataName.LAST_UPDATE_COMMENT,
    header: DocumentMetadataName.LAST_UPDATE_COMMENT,
    cell: (e: Document) => e.lastUpdateComment ?? EMPTY_PLACEHOLDER,
    sortingField:
      DOCUMENT_PROPERTY_KEY[DocumentMetadataName.LAST_UPDATE_COMMENT],
    width: widths[18],
  },
  {
    id: DocumentMetadataName.FILESIZE,
    header: DocumentMetadataName.FILESIZE,
    cell: (e: Document) => toFileSize(e.fileSizeInBytes),
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.FILESIZE],
    width: widths[19],
  },
  {
    id: DocumentMetadataName.DOCUMENT_TAG,
    header: DocumentMetadataName.DOCUMENT_TAG,
    cell: (e: Document) => e.documentTag || EMPTY_PLACEHOLDER,
    sortingField: DOCUMENT_PROPERTY_KEY[DocumentMetadataName.DOCUMENT_TAG],
    width: widths[20],
  },
];
