import { useSearchParams } from "react-router-dom";

export function useOpenedDocumentSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  function setOpenedDocumentTitle(title: string) {
    if (searchParams.get("documentTitle") !== title) {
      searchParams.set("documentTitle", title);
      setSearchParams(searchParams);
    }
  }

  function removeOpenedDocumentTitle() {
    searchParams.delete("documentTitle");
    setSearchParams(searchParams);
  }

  return {
    setOpenedDocumentTitle,
    removeOpenedDocumentTitle,
    openedDocumentTitle: searchParams.get("documentTitle"),
  };
}
